import React, { useState, useEffect } from "react";
import award from "../Image/award.jpg";
import Button from "../Button";

export default function AboutDhakaCast() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handle window resize to detect if the device is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define styles dynamically based on the mobile state
  const dynamicStyles = {
    container: {
      display: "flex",
      flexDirection: isMobile ? "row" : "row", // Stack in a column on mobile
      alignItems: "start",
      justifyContent: "space-between",
      marginBottom: "10px", //
    },
    column: {
      flex: 1,
      marginBottom: isMobile ? "10px" : "0", // Add margin on mobile
    },
    imageContainer: {
      display: "flex",
      justifyContent: isMobile ? "end" : "flex-end",
    },
    image: {
      width: isMobile ? "90%" : "90%", // Reduce image size on mobile
      height: "auto",
      objectFit: "cover",
    },
    title: {
      fontFamily: "Roboto",
      fontSize: isMobile ? "20px" : "40px", // Smaller title on mobile
      color: "#06b4cb",
      marginLeft: isMobile ? "80px" : "80px",
      marginTop: isMobile ? "0px" : "50px", // Add margin on desktop
    },
    description: {
      fontFamily: "Roboto",
      fontSize: isMobile ? "10px" : "24px", // Smaller text on mobile
      textAlign: "justify",
      color: "#333",
      lineHeight: "1.5",
    },
  };

  return (
    <div className="all">
      <h2 style={dynamicStyles.title} className="mb-3">
        ABOUT DHAKACAST
      </h2>
      <div className="container" style={dynamicStyles.container}>
        <div style={dynamicStyles.column}>
          <p style={dynamicStyles.description}>
            Dhaka Cast is a healthcare start-up in Bangladesh focused to support
            diabetic patients with home service fully run by experienced and
            qualified doctors. Our vision is to establish 360-degree solutions
            and services online. We work to provide healthy lifestyle education
            and the best service and products at an affordable price for our
            consumers.
          </p>
          <Button text="Learn More" to="/about" dark position="left" />
        </div>
        <div
          style={{ ...dynamicStyles.column, ...dynamicStyles.imageContainer }}
        >
          {/* Content for the second column */}
          <img
            src={award}
            className="img-fluid"
            style={dynamicStyles.image}
            alt="National Award"
          />
        </div>
      </div>
    </div>
  );
}
