import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 

export default function AchievementItem({id, image, title, description, author, date }) {

  const navigate = useNavigate(); // Initialize the navigation hook

   // Handle "Read More" click and navigate to ItemDetails page with the activity ID


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleReadMore = () => {
    navigate(`/item-details/${id}`, {
      state: { title, description, image, author, date } // Pass data via state
    });
  };

  // Inline styles to control the text overflow and line limits, responsive based on mobile view
  const getStyles = () => ({
    card: {
      width: isMobile ? "100%" : "18rem", // Full width on mobile
    },
    image: {
      height: isMobile ? "150px" : "200px", // Adjust image height for mobile
      objectFit: "cover",
    },
    title: {
      fontSize: isMobile ? "12px" : "14px", // Smaller title font on mobile
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
      lineHeight: "1.5",
    },
    description: {
      fontSize: isMobile ? "10px" : "12px", // Smaller description font on mobile
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineHeight: "1.5",
    },
    button: {
      fontSize: isMobile ? "10px" : "12px", // Smaller button text on mobile
      backgroundColor: "#06b4cb", // Button background color
      color: "white", // Button text color
    },
    footerText: {
      fontSize: isMobile ? "10px" : "12px", // Smaller text for author and date
    },
  });

  const styles = getStyles();

  return (
    <div className="card h-100">
      <img
        src={image || "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"}
        className="card-img-top"
        style={{ height: '180px' }}
        alt={title}
      />
      <div className="card-body">
        <h5 className="card-title" style={styles.title}>{title}</h5> {/* Max 2 lines for title */}
        <p className="card-text" style={styles.description}>{description}</p> {/* Max 2 lines for description */}
        <button onClick={handleReadMore} style={styles.button} className="btn btn-sm">Read More</button>
      </div>
      <div className="card-footer">
        <small className="text-muted" style={styles.footerText}>By: {author} &nbsp; | &nbsp; Date: {date}</small> {/* Change footer text size */}
      </div>
    </div>
  );
}

