import React, { useState, useEffect } from "react";
import { FaUsers, FaDollarSign, FaSmile, FaChartLine } from "react-icons/fa"; // Importing icons
import "./Home.css"; // Import custom styles
import StatisticItem from "./StatisticItem";

export default function Statistic() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handle window resize to adjust the layout for mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const stats = [
    { icon: <FaUsers size={50} />, label: "Number of Users", value: 10000 },
    {
      icon: <FaDollarSign size={50} />,
      label: "Cost less than",
      value: 40,
      suffix: "%",
    },
    { icon: <FaSmile size={50} />, label: "Satisfactory Clients", value: 5000 },
    {
      icon: <FaChartLine size={50} />,
      label: "Growth Rate",
      value: 50,
      suffix: "%",
    },
  ];

  return (
    <div className="statistic-section">
      <div className="overlay">
        <div className="container mt-5">
          <div className="row text-center">
            {stats.map((stat, index) => (
              <div
                className={`col-${isMobile ? "6" : "3"} col`} // Adjust column size based on mobile view
                key={index}
              >
                <StatisticItem
                  icon={stat.icon}
                  label={stat.label}
                  value={stat.value}
                  suffix={stat.suffix}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
