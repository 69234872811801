import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ProductCard = ({ name, dosage, description, company, price, originalPrice, discount, imageUrl }) => {
  return (
    <div className="card" style={{ width: '18rem', position: 'relative' }}>
      {/* Discount Badge */}
      {discount && (
        <span
          className="badge bg-danger"
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            padding: '5px 10px',
          }}
        >
          {discount}% Off
        </span>
      )}

      {/* Product Image */}
      <img
        src={imageUrl}
        className="card-img-top"
        alt={name}
      />

      {/* Card Body */}
      <div className="card-body">
        {/* Product Name */}
        <h5 className="card-title">{name} <span className="text-muted">{dosage}</span></h5>
        {/* Product Description */}
        <p className="card-text text-success" style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
          {description}
        </p>
        {/* Company Information */}
        <p className="card-text text-muted" style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>{company}</p>
      </div>

      {/* Card Footer */}
      <div className="card-footer d-flex justify-content-between align-items-center">
        {/* Discounted and Original Price */}
        <div>
          <h5 className="text mb-0">৳ {price}/Box</h5>
          <small className="text-muted text-decoration-line-through">৳ {originalPrice}/Box
          </small>
        </div>
        {/* Add to Cart Button */}
        <button className="btn btn-primary disabled">
          Add to cart
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
