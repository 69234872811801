import React, { useEffect, useState } from "react";
import "./Home.css";
import PartnerSlider from "./PartnerSlider";
import PartnerData from "../../data/PartnerData";

export default function Partners() {
  const [data, setData] = useState(PartnerData);

  return (
    <div className="container">
      {data.length === 0 ? (
        <div>
          <p>Loading...</p>
        </div>
      ) : (
        <PartnerSlider data={data} />
      )}
    </div>
  );
}
