import React, { useState, useEffect, useRef } from "react";

const AutoScrollingText = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // State to detect mobile view
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const scrollSpeed = 30; // Scrolling speed (ms per frame)
    const scrollStep = 1; // How many pixels to move per frame

    const updateScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollWidth, clientWidth } = scrollContainerRef.current;

        if (scrollPosition >= scrollWidth + clientWidth) {
          setScrollPosition(0); // Reset position if needed, or stop
        } else {
          setScrollPosition((currentPosition) => currentPosition + scrollStep);
        }
      }
    };

    const intervalId = setInterval(updateScroll, scrollSpeed);

    return () => clearInterval(intervalId);
  }, [scrollPosition]);

  const containerStyle = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: isMobile ? "100%" : "80%", // Full width on mobile
    marginLeft: isMobile ? "0" : "120px", // No margin on mobile
    marginRight: "auto",
    fontSize: isMobile ? "14px" : "18px", // Smaller font size on mobile
    color: "red",
  };

  return (
    <div style={containerStyle}>
      <div
        ref={scrollContainerRef}
        style={{
          transform: `translateX(-${scrollPosition}px)`,
          display: "inline-block",
          whiteSpace: "nowrap",
        }}
      >
        {/* Scrolling text content */}
        ডেঙ্গু টেস্ট এখন আরো সহজ হয়ে গেছে, ঘরে বসেই! 😊🏠 ঢাকা কাস্টের মাধ্যমে ঘরে বসে স্যাম্পল দিয়ে 🧪💉 অনলাইনে রিপোর্ট 📊📈 বুঝে নিন। বিস্তারিত জানতে, ০১৩১৫০৮৭৬৮৭ 📞📱নম্বরে কল করুন।
      </div>
    </div>
  );
};

export default AutoScrollingText;
