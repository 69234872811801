import image1 from "../Components/Image/impact/1.jpg";
import image2 from "../Components/Image/impact/2.jpg";
import image3 from "../Components/Image/impact/3.jpg";
import image4 from "../Components/Image/impact/4.jpg";
import image5 from "../Components/Image/impact/5.jpg";
import image6 from "../Components/Image/impact/6.jpg";
import image7 from "../Components/Image/impact/7.jpg";
import image8 from "../Components/Image/impact/8.jpg";
import image9 from "../Components/Image/impact/9.jpg";
import image10 from "../Components/Image/impact/10.jpg";
import image11 from "../Components/Image/impact/11.jpg";
import image12 from "../Components/Image/impact/12.jpg";
import image13 from "../Components/Image/impact/13.jpg";
import image14 from "../Components/Image/impact/14.jpg";
import image15 from "../Components/Image/impact/15.jpg";
import image16 from "../Components/Image/impact/16.jpg";
import image17 from "../Components/Image/impact/17.jpg";

const ImpactData = [
  {
    id: 1,
    image: image1,
    description: "Introduction",
  },
  {
    id: 2,
    image: image2,
    description: "Table of Content",
  },
  {
    id: 3,
    image: image3,
    description: "Founder's Message",
  },
  {
    id: 4,
    image: image4,
    description: "Prevalence of Diabetes",
  },
  {
    id: 5,
    image: image5,
    description: "Prevalence of Diabetes",
  },
  {
    id: 6,
    image: image6,
    description: "Economic burden of Diabetes",
  },
  {
    id: 7,
    image: image7,
    description: "Our Services",
  },
  {
    id: 8,
    image: image8,
    description: "Theory of Change",
  },
  {
    id: 9,
    image: image9,
    description: "Theory of Change",
  },
  {
    id: 10,
    image: image10,
    description: "Impact Goals",
  },
  {
    id: 11,
    image: image11,
    description: "Impact Indicators",
  },
  {
    id: 12,
    image: image12,
    description: "Rural Focus",
  },
  {
    id: 13,
    image: image13,
    description: "Impact Metrics",
  },
  {
    id: 14,
    image: image14,
    description: "Empowering Women",
  },
  {
    id: 15,
    image: image15,
    description: "Testimonial",
  },
  {
    id: 16,
    image: image16,
    description: "A Timeline of Our Journey",
  },
  {
    id: 17,
    image: image17,
    description: "Way Forward",
  },
];

export default ImpactData;
