import React from 'react'
import img1 from "../Components/Image/samplepage.png"
import img2 from "../Components/Image/samplePage.gif"

export default function SampleCollection() {
  return (
    <div>
      <img src={img2} alt="Sample collection image" width="100%" />
    </div>
  )
}
