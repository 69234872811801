import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation

export default function ActivitiesItem({
  id,
  title,
  description,
  image,
  author,
  date,
}) {
  const navigate = useNavigate(); // Initialize the navigation hook

  // Handle "Read More" click and navigate to ItemDetails page with the activity ID and details
  const handleReadMore = () => {
    navigate(`/item-details/${id}`, {
      state: { title, description, image, author, date }, // Pass data via state
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getStyles = () => ({
    card: {
      width: isMobile ? "100%" : "18rem",
    },
    image: {
      height: isMobile ? "150px" : "200px",
      objectFit: "cover",
    },
    title: {
      fontSize: isMobile ? "12px" : "16px",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "bold",
      lineHeight: "1.5",
    },
    description: {
      fontSize: isMobile ? "10px" : "12px",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineHeight: "1.5",
    },
    button: {
      fontSize: isMobile ? "10px" : "12px",
      backgroundColor: "#06b4cb",
      color: "white",
    },
    authorDate: {
      fontSize: isMobile ? "10px" : "12px",
    },
  });

  const styles = getStyles();

  return (
    <div className="my-2">
      <div className="card" style={styles.card}>
        <img
          src={
            image ||
            "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
          }
          className="card-img-top"
          style={styles.image}
          alt="Activity"
        />
        <div className="card-body">
          <p className="card-title" style={styles.title}>
            {title}
          </p>
          <p className="card-text" style={styles.description}>
            {description}
          </p>
          <button
            onClick={handleReadMore}
            style={styles.button}
            className="btn btn-sm"
          >
            Read More
          </button>
          <div className="d-flex justify-content-between mt-2">
            <span className="text-muted small" style={styles.authorDate}>
              {author} &nbsp; | &nbsp; Date: {date}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
