import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import hooks to access passed state

export default function ItemDetails() {
  const location = useLocation(); // Get the data passed via state
  const { title, description, image, author, date } = location.state || {}; // Destructure state data

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on mount

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dynamicStyles = {
    container: {
      display: "flex",
      flexWrap: "wrap",
      gap: "40px",
      margin: isMobile ? "10px 5px" : "40px",
      padding: isMobile ? "10px 10px" : "40px",
    },
    image: {
      width: "100%",
      maxWidth: "500px",
      height: "auto",
      borderRadius: "8px",
    },
    info: {
      flex: 1,
    },
    title: {
      fontSize: "1.5rem",
      marginBottom: "20px",
    },
    description: {
      fontSize: "1rem",
      lineHeight: "1.6",
      marginBottom: "10px",
      textAlign: "justify",
    },
    meta: {
      marginTop: "20px",
      fontSize: "0.9rem",
      color: "#555",
    },
    metaItem: {
      margin: "5px 0",
    },
  };

  return (
    <div style={dynamicStyles.container}>
      <div className="item-image">
        <img src={image} alt={title} style={dynamicStyles.image} />
      </div>
      <div className="item-info" style={dynamicStyles.info}>
        <h3 style={dynamicStyles.title}>{title}</h3>
        <p style={dynamicStyles.description}>{description}</p>
        <div className="item-meta" style={dynamicStyles.meta}>
          <p style={dynamicStyles.metaItem}>
            <strong>Author:</strong> {author}
          </p>
          <p style={dynamicStyles.metaItem}>
            <strong>Date:</strong> {new Date(date).toLocaleDateString()} 
          </p>
        </div>
      </div>
    </div>
  );
}
