import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaFacebook, FaYoutube, FaLinkedin, FaTwitter, FaInstagram } from 'react-icons/fa';
import footerbg from './Image/footerbg.png'

export default function Footer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Dynamic styles based on screen size
  const dynamicStyles = {
    heading: {
      ...styles.heading,
      fontSize: isMobile ? '24px' : '40px',
    },
    contactForm: {
      ...styles.contactForm,
      padding: isMobile ? '10px' : '20px',
    },
    input: {
      ...styles.input,
      padding: isMobile ? '8px' : '10px',
      fontSize: isMobile ? '10px' : '12px',
    },
    textarea: {
      ...styles.textarea,
      padding: isMobile ? '8px' : '10px',
      fontSize: isMobile ? '10px' : '12px',
    },
    submitButton: {
      ...styles.submitButton,
      padding: isMobile ? '8px' : '10px',
      fontSize: isMobile ? '16px' : '18px',
    },
    contactInfo: {
      ...styles.contactInfo,
      fontSize: isMobile ? '14px' : '16px',
      paddingLeft: isMobile ? '10px' : '10px',
      marginTop: isMobile ? '30px' : '0px',

    },
    socialIcon: {
      ...styles.socialIcon,
      fontSize: isMobile ? '24px' : '30px',
      marginRight: isMobile ? '20px' : '30px',
    },
  };

  return (
    <div className="mt-3" style={{ backgroundImage: `url(${footerbg})`, backgroundSize: "cover", backgroundPosition: 'center', height: '620px' }}>
      <div style={styles.container}>
        <div className="row">
          <h2 className="text-center mb-4 pt-5" style={dynamicStyles.heading}>CONTACT US</h2>
          {/* Contact Form */}
          <div className={`col-md-5 mt-5 ${isMobile ? '' : 'offset-md-1'}`}>
            <form style={dynamicStyles.contactForm}>
              <div className="form-group">
                <input type="text" className="form-control mb-3" disabled placeholder="Your Full Name" style={dynamicStyles.input} />
              </div>
              <div className="form-group">
                <input type="text" className="form-control mb-3" disabled placeholder="Phone Number" style={dynamicStyles.input} />
              </div>
              <div className="form-group">
                <input type="email" className="form-control mb-3" disabled placeholder="Email" style={dynamicStyles.input} />
              </div>
              <div className="form-group">
                <textarea className="form-control mb-3" rows="4" disabled placeholder="Message" style={dynamicStyles.textarea}></textarea>
              </div>
              <button type="submit" className="btn btn-info btn-block" disabled style={dynamicStyles.submitButton}>Submit</button>
            </form>
          </div>

          {/* Contact Info */}
          <div className="col-md-5 d-flex align-items-center justify-content-center">
            <div style={dynamicStyles.contactInfo}>
              <p><FaMapMarkerAlt /> House #19, Road #5, Sector #11, Uttara, Dhaka 1205.</p>
              <p><FaPhoneAlt /> +8801315087687</p>
              <p><FaEnvelope /> hello@dhakacast.com</p>
              <p><FaEnvelope /> dhakacast@gmail.com</p>
              <div style={styles.socialIcons}>
                <a href="https://www.facebook.com/dhakacastltd" target="_blank" rel="noopener noreferrer" style={dynamicStyles.socialIcon}><FaFacebook /></a>
                <a href="https://www.youtube.com/@dhakacast3519" target="_blank" rel="noopener noreferrer" style={dynamicStyles.socialIcon}><FaYoutube /></a>
                <a href="https://www.linkedin.com/company/dhaka-cast-ltd/" target="_blank" rel="noopener noreferrer" style={dynamicStyles.socialIcon}><FaLinkedin /></a>
                <a href="https://x.com/DhakaCast" target="_blank" rel="noopener noreferrer" style={dynamicStyles.socialIcon}><FaTwitter /></a>
                <a href="https://www.instagram.com/dhakacast/" target="_blank" rel="noopener noreferrer" style={dynamicStyles.socialIcon}><FaInstagram /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  heading: {
    color: 'white',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  contactForm: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  submitButton: {
    backgroundColor: '#06b4cb',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
    fontSize: '18px',
    fontFamily: 'Roboto',
  },
  contactInfo: {
    color: 'white',
    fontSize: '16px',
    fontFamily: 'Roboto'
  },
  socialIcons: {
    marginTop: '40px',
  },
  socialIcon: {
    color: 'white',
    marginRight: '30px',
    fontSize: '30px',
  },
};
