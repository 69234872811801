import React, { useEffect, useState }  from 'react';
import AchievementItem from '../AchievementItem';
import './Home.css';
import Button from "../Button";
import AchivementData from "../../data/AchivementData";

export default function Achievement() {
  const [data, setData] = useState(AchivementData);
  

  // Sort activities by published date (newest first)
  const sortedAchivement = data.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

   useEffect(() => {
     const handleResize = () => {
       setIsMobile(window.innerWidth <= 768);
     };

     window.addEventListener("resize", handleResize);
     return () => {
       window.removeEventListener("resize", handleResize);
     };
   }, []);


  // Get the latest 4 activities
  let latestAchivement;
  {
    isMobile
      ? (latestAchivement = sortedAchivement.slice(0, 2))
      : (latestAchivement = sortedAchivement.slice(0, 4));
  }

  const getStyle = {
    Gap: {
      marginTop: isMobile ? "20px" : "40px",
      marginBottom: isMobile ? "20px" : "40px",
      marginLeft: isMobile ? "0" : "auto",
      marginRight: isMobile ? "0" : "auto",
    },
  };


  return (
    <div className="container">
      <div className="container achivement-container mt-1">
        <h2
          className="text-center mb-2 mt-3"
          style={{ color: "#06b4cb", fontFamily: "Roboto" }}
        >
          OUR ACHIEVEMENTS
        </h2>
        <hr
          style={{
            border: "2px solid #ffffff",
            width: "350px",
            margin: "0 auto",
          }}
        />
        <div className="row mt-5">
          {latestAchivement.map((achievement, index) => (
            <div
              key={index}
              className={`mb-4 ${
                isMobile ? "col-6" : "col-12 col-sm-6 col-md-4 col-lg-3"
              }`}
            >
              <AchievementItem
                id={achievement.id}
                title={achievement.title}
                description={achievement.description}
                author={achievement.author}
                date={achievement.date}
                image={achievement.image}
              />
            </div>
          ))}
        </div>
        <Button to="/achievement" text="Learn More" dark position={"center"} />
      </div>
    </div>
  );
}

const styles = {
  button: {
    backgroundColor: '#fff', // Button background color
    color: '#06b4cb', // Button text color
    border: 'none', // Remove border
    padding: '10px 20px', // Padding for the button
    borderRadius: '5px', // Rounded corners
    cursor: 'pointer', // Pointer cursor on hover
    alignItems: 'center', // Align icon with text
  },
  icon: {
    marginLeft: '10px', // Space between text and arrow icon
  },
}
