import React, { useEffect, useState } from "react";
import ImpactSlider from "./ImpactSlider";
import ImpactData from "../data/ImpactData";

export default function Impact() {
  const [data, setData] = useState(ImpactData);


  return (
    <div className="container">
      {data.length === 0 ? (
        <div>
          <p>Loading...</p>
        </div>
      ) : (
        <ImpactSlider data={data} />
      )}
    </div>
  );
}
