import React, { useState, useEffect } from "react";
import "../App.css";
import { Link, useLocation } from "react-router-dom";

export default function NavBar() {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // State to detect mobile view
  const [isNavOpen, setIsNavOpen] = useState(false); // State to track navbar open/close

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("user");
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen); // Toggle navbar open/close
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white">
      <div className="container-fluid">
        {/* Brand Logo */}
        <Link className="navbar-brand" to="/">
          <img
            src={`${process.env.PUBLIC_URL}/dcLogo.png`}
            width={isMobile ? "120px" : "160px"}
            height={isMobile ? "32px" : "42px"}
            alt="Logo"
          />
        </Link>

        {/* Navbar Toggler Button for Mobile View */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNav}
          aria-controls="navbarSupportedContent"
          aria-expanded={isNavOpen ? "true" : "false"}
          aria-label="Toggle navigation"
          style={{
            fontSize: "1.2rem", // Smaller icon for mobile
            outline: "none",
            border: "none",
          }}
        >
          {isNavOpen ? (
            <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              &times;
            </span> // Cross icon when open
          ) : (
            <span className="navbar-toggler-icon"></span> // Default hamburger icon
          )}
        </button>

        {/* Collapsible Navbar Content */}
        <div
          className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
          id="navbarSupportedContent"
        >
          <ul
            className="navbar-nav mb-2 mb-lg-0"
            style={{
              textAlign: isMobile ? "center" : "left",
              width: isMobile ? "100%" : "auto",
            }}
          >
            <li className="nav-item">
              <Link className={`nav-link ${isActive("/")}`} to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive("/about")}`} to="/about">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${isActive("/achievement")}`}
                to="/achievement"
              >
                Achievement
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive("/shop")}`} to="/shop">
                Shop
              </Link>
            </li>

            {/* Services Dropdown */}
            {isMobile ? (
              <ul
                className="navbar-nav mb-2 mb-lg-0"
                style={{
                  textAlign: isMobile ? "center" : "left",
                  width: isMobile ? "100%" : "auto",
                }}
              >
                <li className="nav-item ">
                  <Link
                    className={`nav-link ${isActive("/doctor")}`}
                    to="/doctor"
                  >
                    Doctor
                  </Link>
                </li>
                
                <li className="nav-item ">
                  <Link
                    className={`nav-link ${isActive("/healthTips")}`}
                    to="/healthTips"
                  >
                    Health Tips
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${isActive("/sampleCollection")}`}
                    to="/sampleCollection"
                  >
                    Sample Collection
                  </Link>
                </li>
              </ul>
            ) : (
              <li className="nav-item dropdown">
                <Link
                  className={`nav-link dropdown-toggle ${
                    isActive("/doctor") ||
                    isActive("/healthTips") ||
                    isActive("/sampleCollection")
                      ? "active"
                      : ""
                  }`}
                  to="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      className={`dropdown-item ${isActive("/doctor")}`}
                      to="/doctor"
                    >
                      Doctor
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`dropdown-item ${isActive("/healthTips")}`}
                      to="/healthTips"
                    >
                      Health Tips
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`dropdown-item ${isActive(
                        "/sampleCollection"
                      )}`}
                      to="/sampleCollection"
                    >
                      Sample Collection
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            <li className="nav-item">
              <Link
                className={`nav-link ${isActive("/research")}`}
                to="/research"
              >
                Research
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive("/impact")}`} to="/impact">
                Impact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
