import image1 from "../Components/Image/yasir.jpeg";
import image2 from "../Components/Image/tina.jpg";
import image3 from "../Components/Image/iqbal.jpeg";
import image4 from "../Components/Image/asif.png";
import image5 from "../Components/Image/shaon.jpg";

const ReviewData = [
  {
    id: 1,
    name: "Yasir Azman",
    image: image1,
    title: "CEO, Grameenphone Company Ltd.",
    description:
      "We are very proud of you and your team! And I am so happy to see you all shining. Dream big, you shall surely continue making the nation proud!",
  },

  {
    id: 2,
    name: "Tina F. Jabeen",
    image: image2,
    title: "Former Managing Director Startup Bangladesh Limited",
    description:
      "For the last few months, Dhaka Cast has been my go to 'rescue-me' telemed site for various serious and not-so-serious health needs for my HUGE family. I am truly indebted to Dhaka Cast who saved me from all kinds of urgent medical needs.I wish Dr. Fahreen Hannan all the best with Dhaka Cast",
  },

  {
    id: 3,
    name: "ইকবাল বাহার জাহিদ",
    image: image3,
    title: "প্রতিষ্ঠাতা নিজের বলার মতো একটা গল্প",
    description:
      "আমার মা বয়স্ক, করোনাকালীন সময়ে ডায়বেটিক জনিত চেকাপের জন্য চেম্বার এ ভিড়ের মধ্যে নিয়ে যাওয়া ঠিক হবে কিনা সে আশংকায় ছিলাম। হঠাৎ মাথায় আসলো ঢাকা কাস্ট কাজ করছে ডায়বেটিস রুগীদের সেবা নিয়ে। যোগাযোগ করতেই তারা বাসায় ডাক্তার পাঠিয়ে দিল ফলো আপের জন্য। সারাদিন ব্যবসায়িক কাজ আর সামাজিক কাজে নিজেকে ব্যস্ত রাখতে হয়। সেদিক থেকে মায়ের জন্য সর্বোচ্চ সেবা দিতে ঢাকা কাস্টের সার্ভিস আমার জন্য আশীর্বাদ এর মতো।",
  },

  {
    id: 4,
    name: "Asif Uddin Ahmed",
    image: image4,
    title: "Assistant Professor, ULAB & Acting Director, EMK Centre.",
    description:
      "With aging parent suffering from diabetic and two teenage children, managing family health has been extremely difficult. The first priority has been to keep the family safe from getting infected. But the major challenges till now has been to take my mother through regular checkup and consulting a doctor during emergency need. That's where I think Dhaka Cast and Dr. Farheen Hannan has been a tower of support for my family. Dhaka Cast and its professional team has been providing me online family health support to keep my family safe during a diffi cult team.",
  },
  {
    id: 5,
    name: "Meher Afroze Shawon",
    image: image5,
    title: "Media Personality & Architect",
    description:
      "I am a prediabetes patient and I use awesome services of Dhaka Cast for a long time. It keeps my suger checked.",
  },
];

export default ReviewData;
