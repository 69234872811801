import React,{ useState, useEffect, useRef } from 'react'
import CountUp from "react-countup";

function StatisticItem({ index, icon, label, value, suffix }) {
  const [inView, setInView] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setInView(entry.isIntersecting);
      },
      { threshold: 0.5 } // Trigger when 50% of the section is in view
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div key={index} className="col-md-3" ref={sectionRef}>
      {" "}
      {/* 4 columns */}
      <div className="icon" style={{ color: "white" }}>
        {icon}
      </div>
      <h2 className="mt-2" style={{ color: "white", fontFamily: "Roboto" }}>
        {inView && (
          <CountUp start={0} end={value} duration={2} suffix={suffix || ""} />
        )}
      </h2>
      <p style={{ color: "white" }}>{label}</p>
    </div>
  );
}

export default StatisticItem
