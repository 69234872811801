import React, { useState, useEffect } from 'react';

export default function ReviewItem({ name, image, title, description }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Dynamic styles based on isMobile state
  const imageSize = isMobile ? '80px' : '150px';
  const textAlign = isMobile ? 'center' : 'start';
  const containerPadding = isMobile ? '0' : '0';
  const descriptionSize = isMobile ? '12px' : '16px';
  const titleSize = isMobile ? '14px' : '18px';
  const nameSize = isMobile ? '18px' : '24px';
  const colSize = isMobile ? '12' : '9';

  return (
    <div className="row d-flex justify-content-center" style={{ padding: containerPadding }}>
      <div className="col-lg-10 col-xl-8">
        <div className="row">
          <div className="col-lg-4 d-flex justify-content-center">
            <img
              src={image}
              style={{ objectFit: 'cover', width: imageSize, height: imageSize }}
              className="rounded-circle shadow-1 mb-4 mb-lg-0"
              alt="avatar"
            />
          </div>
          <div className={`col-${colSize} col-md-9 col-lg-7 col-xl-8 text-${textAlign}`}>
            <p className="mb-2" style={{ fontSize: nameSize }}>{name}</p>
            <p className="mb-3" style={{ fontSize: titleSize }}>{title}</p>
            <p className="mb-0 pb-3" style={{ fontSize: descriptionSize }}>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
