import image1 from "../Components/Image/doctor/montasir islam2.png";
import image2 from "../Components/Image/doctor/kiron1.png";
import image3 from "../Components/Image/doctor/Dr. m a Rahman.png";
import image4 from "../Components/Image/doctor/dr. mahid khan.png";
import image5 from "../Components/Image/doctor/dr. ejaj bari.png";
import image6 from "../Components/Image/doctor/Dr sayed inam.png";
import image7 from "../Components/Image/doctor/rezwana1.png";
import image8 from "../Components/Image/doctor/Dr. arif uddin.png";
import image9 from "../Components/Image/doctor/dr. foisal qadir.png";


const DoctorData = [
  {
    id: 1,
    name: "Prof. Dr. Mohammed Montasir Islam",
    degree: "MBBS, MCPS, FCGP, M.Phill CCD(Birdem)",
    designation: "Senior Consultant",
    hospital: "Consultant, Dhaka Cast.",
    fee: "700",
    followUpFee: "500",
    specialization: "Diabetologist & Medicine Specialist.",
    experienceYear: "18",
    aboutDoctor: "BMDC Reg, No - A 28502",
    image: image1,
  },
  {
    id: 2,
    name: "Dr. Sheikh Ahmedul Haque",
    degree: "MBBS (Dhaka) BLS Training (American Heart Association)",
    designation: "Chief Consultant",
    hospital: "Chief Consultant, Dhaka Cast.",
    fee: "400",
    followUpFee: "300",
    specialization: "General",
    experienceYear: "7",
    aboutDoctor: "BMDC Reg No - 74775",
    image: image2,
  },
  {
    id: 3,
    name: "Professor Dr. M. A. Rahman",
    degree: "MBBS, FCPS (Ophthalmology)",
    designation:
      "General Ophthalmologist & Anterior Segment Surgeon.Professor and Head, Department of Ophthalmology,",
    hospital: "International Medical College & Dhaka Cast",
    fee: "1000",
    followUpFee: "700",
    specialization: "General Ophthalmologist & Anterior Segment Surgeon",
    experienceYear: "17",
    aboutDoctor: "",
    image: image3,
  },
  {
    id: 4,
    name: "Dr. Mohammed Abdul Mahid Khan",
    degree:
      "MBBS (Dhaka) D-Card(Cardiology) MRCP(Medicine) Ireland, Fellowship in DM(India)",
    designation: "Senior Consultant",
    hospital: "Senior Consultant, Dhaka Cast.",
    fee: "800",
    followUpFee: "600",
    specialization: "Cardiologist",
    experienceYear: "20",
    aboutDoctor: "BMDC Reg No - 23810",
    image: image4,
  },
  {
    id: 5,
    name: "Dr. Md. Ejaj Bari Choudhury",
    degree:
      "MBBS (DMC), CCD (BIRDEM), Post Graduate in Diabetes (Johns Hopkins - USA)",
    designation: "Diabetologist & Head of Diabetes Center,",
    hospital:
      "City Hospital & Medinova (Dhanmondi), Senior Consultant, Dhaka Cast.",
    fee: "700",
    followUpFee: "500",
    specialization: "Diabetologist & Medicine Specialist.",
    experienceYear: "12",
    aboutDoctor: "BMDC Reg, No: A 39258",
    image: image5,
  },
  {
    id: 6,
    name: "Dr. Mohammad Sayed Inam",
    degree: "MBBS(DMC) , M.Phil (Psychiatry) BCS (Health)",
    designation: "Psychiatrist, Dhaka Cast",
    hospital:
      "Assistant Professor Sylhet M A G Osmani Medical College and Hospital. International Associate Member, Royal College of Psychiatrists, England.",
    fee: "1000",
    followUpFee: "700",
    specialization: "Psychiatrist",
    experienceYear: "15",
    aboutDoctor: "BMDC Reg- A 34887",
    image: image6,
  },
  {
    id: 7,
    name: "Dr. Rezwana Biswas",
    degree: "MBBS (Dhaka) CCD(BIRDEM)",
    designation: "Consultant, Dhaka Cast",
    hospital: "Dhaka Cast.",
    fee: "500",
    followUpFee: "300",
    specialization: "Gyne Specialist",
    experienceYear: "8",
    aboutDoctor: "BMDC Reg No - 67368",
    image: image7,
  },
  {
    id: 8,
    name: "Dr. Arif Uddin Ahmed",
    degree: "MBBS, DDV",
    designation: "Associate Professor (Dermatology)",
    hospital: "Dhaka Cast",
    fee: "700",
    followUpFee: "500",
    specialization: "Skin & VD Specialist",
    experienceYear: "12",
    aboutDoctor: "BMDC Reg No - A 41541",
    image: image8,
  },
  {
    id: 9,
    name: "Dr. Foisal Quader Chowdhury",
    degree: "MBBS, CMU MS(Urology)",
    designation: "Consultant, Dhaka Cast",
    hospital: "Consultant, Dhaka Cast",
    fee: "600",
    followUpFee: "400",
    specialization: "Urologist",
    experienceYear: "18",
    aboutDoctor: "BMDC Reg No - 50014",
    image: image9,
  },
];

export default DoctorData;
