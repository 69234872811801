import React, { useEffect, useState } from 'react';
import './Home.css';
import img1 from "../Image/b1.jpg"
import img2 from "../Image/b2.jpg"
import img3 from "../Image/banner3.jpg"
import img4 from "../Image/banner4.jpg"

export default function HeaderSlider() {
  const [data, setData] = useState([]);

  useEffect(() => {
    
      const demoData = [
        {
          id: 1,
          image: img1,
          description: "Special Discount for Dhaka Cast Member",
        },
        
        {
          id: 2,
          image: img3,
          description: "Medicine home delivery service",
        },
        {
          id: 3,
          image: img4,
          description: "Lab test at your home.",
        },
      ];

    // Simulating a network delay to mimic fetching data
    setTimeout(() => {
      setData(demoData);
    }, 1000);
  
  }, []);

  

  return (
    <div>
      <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {data.length === 0 ? (
            <div className="carousel-item active">
              <p>Loading...</p>
            </div>
          ) : (
            data.map((item, index) => (
              <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={item.id}>
                <img src={item.image} className="d-block w-100" alt={item.description} />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
