import React, { useEffect, useState } from 'react';
import HealthTipsItem from './HealthTipsItem';
import HealthTipsData from "../data/HealthTipsData";

export default function HealthTips() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [data, setData] = useState(HealthTipsData);
 
  const sortedActivities = Array.isArray(data)
    ? data.sort((a, b) => new Date(b.date) - new Date(a.date))
    : [];

  // Handle window resize to adjust the layout for mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="container mt-3">
      <h2 className="text-center mt-4 mb-4" style={{ color: "#06b4cb" }}>Health Tips</h2>
      <div className="row">
        {sortedActivities.map((tips, index) => (
          <div key={index} className={`mb-4 ${isMobile ? "col-6" : "col-12 col-sm-6 col-md-4 col-lg-3"
            }`}>
            <HealthTipsItem
              id={tips.id}
              title={tips.title}
              description={tips.description}
              image={tips.image}
              author={tips.author}
              date={tips.date}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
