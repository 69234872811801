import React, { useContext } from "react";
import Products from "./Products";

export default function Shop() {
  // Access addToCart from context

  return (
    <div>
      <Products />
    </div>
  );
}
