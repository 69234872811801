import React from 'react';
import ProductCard from './ProductCard';
import img1 from "../Components/Image/medicine/sergel.webp"
import img2 from "../Components/Image/medicine/nexium.webp"
import img3 from "../Components/Image/medicine/vivachek.webp"
import img4 from "../Components/Image/medicine/onetouch.jpg"
import img5 from "../Components/Image/medicine/matchwebp.webp"

const Products = () => {
  // Sample product data
  const products = [
    // Uncomment to test the empty state
    {
      id: 1,
      name: 'Sergel',
      dosage: '20 mg',
      description: 'Esomeprazole Magnesium Trihydrate',
      company: 'Healthcare Pharmaceuticals Ltd.',
      price: 644,
      originalPrice: 700,
      discount: "8",
      imageUrl: img1,
    },
    {
      id: 2,
      name: 'Nexum',
      dosage: '20 mg',
      description: 'Esomeprazole Magnesium Trihydrate',
      company: 'Square Pharmaceuticals PLC.',
      price: 378.90,
      originalPrice: 412.00,
      discount: "10",
      imageUrl: img2, 
    },
    {
      id: 3,
      name: 'VivaChek Ino Test Strip',
      dosage: '50 Strips',
      description: 'Test Strips',
      company: 'Vivacheck Biotech Inc. Ltd',
      price: 966.00,
      originalPrice: 1050.00,
      discount: "8",
      imageUrl: img3, 
    },
    {
      id: 4,
      name: 'OneTouch Verio',
      dosage: '50 Strips',
      description: 'OneTouch Verio',
      company: 'Mundipharma (BD) Pvt. Ltd.',
      price: 1911.00,
      originalPrice: 1950.00,
      discount: "2",
      imageUrl: img4, 
    },
    {
      id: 5,
      name: 'Match Test Strip',
      dosage: '50 Strips',
      description: 'Test Strips',
      company: 'VivaCheck Laboratories Inc',
      price: 1026.00,
      originalPrice: 1080.00,
      discount: "5",
      imageUrl: img5, 
    }
  ];

  return (
    <div className="container my-4">
      {products.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
          <h2>Coming soon...</h2>
        </div>
      ) : (
        <div className="row">
          {products.map((product) => (
            <div className="col-md-3 mb-4" key={product.id}>
              <ProductCard
                name={product.name}
                dosage={product.dosage}
                description={product.description}
                company={product.company}
                price={product.price}
                originalPrice={product.originalPrice}
                discount={product.discount}
                imageUrl={product.imageUrl}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Products;
