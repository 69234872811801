import React, { useState, useEffect } from 'react';
import { FaUserMd, FaUserNurse, FaVials, FaUtensils, FaHeartbeat, FaCloud } from 'react-icons/fa';
import './Home.css';// Import custom CSS
import ServiceCardItem from '../ServiceCardItem';

export default function Services() {

   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

   // Handle window resize to adjust the layout for mobile
   useEffect(() => {
     const handleResize = () => {
       setIsMobile(window.innerWidth <= 768);
     };

     window.addEventListener("resize", handleResize);
     return () => {
       window.removeEventListener("resize", handleResize);
     };
   }, []);

  const services = [
    {
      icon: <FaUserMd size={isMobile ? 35 : 50} />,
      title: "24/7 specialist doctor consultancy both online and offline",
      buttonLabel: "Schedule Appointment",
      buttonLink: "/doctor",
    },
    {
      icon: <FaUserNurse size={isMobile ? 35 : 50} />,
      title: "Nurse, caregiver, physiotherapy service at home",
      buttonLabel: "Schedule Appointment",
      buttonLink: "/",
    },
    {
      icon: <FaUtensils size={isMobile ? 35 : 50} />,
      title: "Diabetic medication and food home delivery",
      buttonLabel: "Order Now",
      buttonLink: "/shop",
    },
    {
      icon: <FaVials size={isMobile ? 35 : 50} />,
      title: "Diagnostic and lab support",
      buttonLabel: "Give Sample",
      buttonLink: "/sampleCollection",
    },
    {
      icon: <FaHeartbeat size={isMobile ? 35 : 50} />,
      title: "Dietician and nutritionist service",
      buttonLabel: "Service Upcoming",
      buttonLink: "/",
    },
    {
      icon: <FaCloud size={isMobile ? 35 : 50} />,
      title: "Cloud-based continuous glucose monitoring",
      buttonLabel: "Service Upcoming",
      buttonLink: "/",
    },
  ];

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4" style={{ color: '#06b4cb', fontFamily: 'Roboto' }}>OUR SERVICES</h2>
      <hr style={{ border: '1px solid #06b4cb', width: isMobile ? '90%' : '700px', margin: '0 auto' }} />
      <div className="row mt-4">
        {services.map((service, index) => (
        <ServiceCardItem key={index} icon={service.icon} title={service.title} buttonLabel={service.buttonLabel} buttonLink={service.buttonLink} />
        ))}
      </div>
    </div>
  );
}
