

const ResearchData = [
  {
    id: 1,
    title: "DIABETES, AN EMERGING EPIDEMIC",
    name: "Dr. Abdur Rafi",
    designation: "Chief Research Officer, Dhaka Cast Ltd.",
    link: "https://drive.google.com/uc?export=download&id=1FBiKNw43VBC8I4qQ7C7iJDeeA9bghL_l",
  },
  {
    id: 2,
    title: "IDLC INTERVIEW WITH DHAKA CAST LTD.",
    name: "Fahreen Hannan",
    designation: "Founder, Dhaka Cast Ltd.",
    link: "https://drive.google.com/uc?export=download&id=1oHwUCbH15XyHRhPB67yCfPJ2wuVoVCQ4",
  },
];

export default ResearchData;