import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'

function ServiceCardItem({ index, icon, title, buttonLink, buttonLabel }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Handle window resize to adjust the layout for mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Styles for mobile and desktop
  const cardStyles = {
    height: isMobile ? "170px" : "260px",
    width: isMobile ? "160px" : "auto",
    borderRadius: "10px",
    padding: isMobile ? "2px" : "20px", // Adjust padding based on screen size
  };

  const titleStyles = {
    fontFamily: "Roboto",
    fontSize: isMobile ? "10px" : "18px", // Smaller font size for mobile
  };
  const iconStyles = {    
    maginTop: isMobile ? "10px" : "20px",  // Adjust icon size for mobile
    color: "#06b4cb",
  };

  const buttonStyles = {
    backgroundColor: "#06b4cb",
    fontFamily: "Roboto",
    fontSize: isMobile ? "10px" : "16px", // Adjust button font size for mobile
    padding: isMobile ? "3px 5px" : "10px 15px", // Smaller button padding on mobile
  };

  return (
    <div key={index} className= {isMobile ? "col-6 mb-4" : "col-md-4 mb-4"}>
      <div className="card shadow-sm text-center" style={cardStyles}>
        <div className="card-body">
          <div className="mb-3" style={iconStyles}>
            {icon}
          </div>
          <p  style={titleStyles}>
            {title}
          </p>
          <Link to={buttonLink} className="btn service-button text-white" style={buttonStyles}>
            {buttonLabel}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ServiceCardItem;
