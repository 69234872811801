import image1 from "../Components/Image/partner/ideshi.png";
import image2 from "../Components/Image/partner/thyrocare.png";
import image3 from "../Components/Image/partner/kumuddin.png";
import image4 from "../Components/Image/partner/medeasy.png";
import image5 from "../Components/Image/partner/newlife.png";
import image6 from "../Components/Image/partner/deshpoli.png";


const PartnerData = [
  {
    id: 1,
    image: image1,
    description:"ideSHi",
  },
  {
    id: 2,
    image: image2,
    description:"Thyrocare",
  },
  {
    id: 3,
    image: image3,
    description:"Kumuddin",
  },
  {
    id: 4,
    image: image4,
    description:"MedEasy",
  },
  {
    id: 5,
    image: image5,
    description:"NewLife",
  },
  {
    id: 6,
    image: image6,
    description:"Desh Politechnic",
  },

];

export default PartnerData;