import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

function Button({ to, text, dark, position }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getStyles = () => {
    return {
      button: {
        fontFamily: "Roboto",
        fontSize: isMobile ? "12px" : "16px", // Adjust font size for mobile
        backgroundColor: dark ? "#06b4cb" : "#fff",
        color: dark ? "#fff" : "#06b4cb",
        border: "none", // Remove border
        padding: isMobile ? "5px 10px" : "10px 20px", // Adjust padding for mobile
        borderRadius: "5px", // Rounded corners
        cursor: "pointer", // Pointer cursor on hover
        display: "inline-flex", // Align icon with text
        alignItems: "center",
        justifyContent: "center",
      },
      icon: {
        marginLeft: "10px", // Space between text and arrow icon
      },
    };
  };

  const styles = getStyles();

  return (
    <div className={`text-${position} mt-2 mb-2`}>
      <Link to={to} className="btn" style={styles.button}>
        {text} <FaArrowRight style={styles.icon} />
      </Link>
    </div>
  );
}

export default Button;
